import * as React from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepContent from '@mui/material/StepContent';
import Button from '@mui/material/Button';
import { Avatar, Chip, CircularProgress, Grid, Skeleton, StepConnector, StepIconProps, TextField, Theme, ThemeProvider, Typography, createTheme, outlinedInputClasses, stepConnectorClasses, styled, useTheme } from '@mui/material';
/* import SettingsIcon from '@mui/icons-material/Settings'; */
/* import GroupAddIcon from '@mui/icons-material/GroupAdd'; */
import LocationOnIcon from '@mui/icons-material/LocationOn';
import VideoLabelIcon from '@mui/icons-material/VideoLabel';
import AndroidIcon from '@mui/icons-material/Android';
import AppleIcon from '@mui/icons-material/Apple';
import LaptopWindowsIcon from '@mui/icons-material/LaptopWindows';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'; import "./stepper_style.css"
import { Stack } from '@mui/joy';
import { ES, IT, NL, RU, US } from 'country-flag-icons/react/3x2';
import { useLocation, useNavigate } from 'react-router-dom';
import DevicesIcon from '@mui/icons-material/Devices';
import * as Flags from 'country-flag-icons/react/3x2'
import QrCode2Icon from '@mui/icons-material/QrCode2';
import { current_user, g_language, getUrl } from '../../core/UrlService';
import LinkIcon from '@mui/icons-material/Link';
import NetworkCheckIcon from '@mui/icons-material/NetworkCheck';
import DynamicFeedIcon from '@mui/icons-material/DynamicFeed';
import WifiPasswordIcon from '@mui/icons-material/WifiPassword';
import { Flag } from '@mui/icons-material';
import LocationCityIcon from '@mui/icons-material/LocationCity';
import StarIcon from '@mui/icons-material/Star';
import "./styles.css"
import { useTranslation } from 'react-i18next';
import { bg_color, button_color, button_text_color, secondary_bg_color, text_color, themeTelegram } from '../../themes/themeTelegram';
import { color } from 'framer-motion';
import useSticky from '../../core/useSticky';
import classNames from "classnames";
import TvIcon from '@mui/icons-material/Tv';
import WifiIcon from '@mui/icons-material/Wifi';
import LanguageIcon from '@mui/icons-material/Language';
import RouterIcon from '@mui/icons-material/Router';

const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
        top: 22,
        marginLeft: 22

    },
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
        [`& .${stepConnectorClasses.vertical}`]: {
            marginLeft: 22
        },

    },
    [`&.${stepConnectorClasses.active}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            width: '2px !important',
            backgroundImage:
                'linear-gradient( 136deg, ' + button_color + ' 0%, ' + button_color + ' 100%)',
        },
    },
    [`&.${stepConnectorClasses.completed}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            width: '2px !important',


            backgroundImage:
                'linear-gradient( 136deg, ' + button_color + ' 0%, ' + button_color + ' 100%);',
        },
    },
    [`& .${stepConnectorClasses.line}`]: {
        marginLeft: 10,
        height: 3,
        border: "none",
        /*  backgroundColor:
             theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0', */
        borderRadius: 1,

    },
}));


const ColorlibStepIconRoot = styled('div')<{
    ownerState: { completed?: boolean; active?: boolean };
}>(({ theme, ownerState }) => ({
    backgroundColor:
        theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#ccc',
    zIndex: 1,
    color: '#fff',
    width: 50,
    height: 50,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
    ...(ownerState.active && {
        backgroundImage:
            'linear-gradient( 136deg, ' + button_color + ' 0%, ' + button_color + ' 100%)',
        boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
    }),
    ...(ownerState.completed && {
        backgroundImage:
            'linear-gradient( 136deg, ' + button_color + '  0%, r' + button_color + '  100%);',
    }),
}));



function ColorlibStepIcon(props: StepIconProps) {
    const { active, completed, className } = props;

    const icons: { [index: string]: React.ReactElement } = {
        1: <LocationCityIcon />,
        2: <WifiPasswordIcon />,
        3: <DevicesIcon />,
        4: <LocationOnIcon />,
        5: <VideoLabelIcon />,
        6: <LocationCityIcon />,
    };

    return (
        <ColorlibStepIconRoot
            ownerState={{ completed, active }}

            className={className}
        >
            {icons[String(props.icon)]}
        </ColorlibStepIconRoot>
    );
}



export default function CreateCode(props: any) {
    const { sticky, stickyRef } = useSticky();

    const navigate = useNavigate();
    const [activeStep, setActiveStep] = React.useState(0);

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleReset = () => {
        setActiveStep(0);
    };

    const location = useLocation();

    const [device, setDevice] = React.useState(1);

    const [protocol, setProtocol] = React.useState(2);

    const [startCity, setStartCity] = React.useState(1);

    const [devices, setDevices] = React.useState([]);

    var onDeviceClick = (id: any) => {

        if (id == 999 || id == 998 || id == 997) {
            navigate("/whypn_routers")
            return
        }
        if (startCity == 2 && (id == 100 || id == 4))
            return
        setDevice(id)

        getAvailableContries(id, startCity, id)

        getTariff(startCity, id, protocol, country)

    }




    var onStartClick = (id: any) => {


        setStartCity(id)
        getAvailableContries(protocol, id, device)
        getDevices(id)

        getTariff(id, device, protocol, country)
    }


    var onTariffClick = (id: any) => {


        var a = tariffs.find((x: any) => x.Id == id)
        setCurrentTariff(a)
    }

    var onProcolClick = (id: any) => {
        setProtocol(id)
        getAvailableContries(id, startCity, device)
        getTariff(startCity, device, id, country)

    }

    const [chips, setChips] = React.useState([]);


    var getFromCountrues = () => {
        fetch(getUrl("/api/from_countries", props.tg))
            //fetch("http://localhost:5010" + "/api/available")
            .then(res => res.json())
            .then(
                (result) => {

                    var r = result.filter(x => x.Id != 2)
                    setFromCountries(r);
                },
                (error) => {
                    //setIsLoaded(true);
                    //setError(error);
                }
            )

    }





    var getDevices = (id) => {

        fetch(getUrl("/api/devices", props.tg) + "type=" + id)
            //fetch("http://localhost:5010" + "/api/available")
            .then(res => res.json())
            .then(
                (result) => {

                    var r = { Id: 997, "Name": "TV / SmartTV" }
                    result.push(r)

                    var r = { Id: 998, "Name": "Роутер" }
                    result.push(r)

                    var r = { Id: 999, "Name": "Другое устройство" }
                    result.push(r)


                    setDevices(result);


                    //getTariff(id, result[0].Id, protocol, country)
                },
                (error) => {
                    //setIsLoaded(true);
                    //setError(error);
                }
            )

    }

    var getFromCountrues = () => {
        fetch(getUrl("/api/from_countries", props.tg))
            //fetch("http://localhost:5010" + "/api/available")
            .then(res => res.json())
            .then(
                (result) => {

                    debugger
                    var r = result.filter(x => x.Id != 2)
                    setFromCountries(r);
                },
                (error) => {
                    //setIsLoaded(true);
                    //setError(error);
                }
            )

    }


    var getAvailableContries = (id: any, country: any, device: any) => {
        fetch(getUrl("/api/available", props.tg) + "type=" + id + "&country=" + country + "&device=" + device)
            //fetch("http://localhost:5010" + "/api/available")
            .then(res => res.json())
            .then(
                (result) => {


                    setCountries(result[0].Flag)
                    setAvailable(result);
                },
                (error) => {
                    //setIsLoaded(true);
                    //setError(error);
                }
            )

    }


    const [isTariffComputing, setIsTariffComputing] = React.useState(false);
    const [tariffs, setTariffs] = React.useState([]);
    const [currentTariff, setCurrentTariff] = React.useState<any>({});

    var getTariff = (startCity, device, protocol, resultCity) => {

        //Здесь так потому, что на бэке и фронты перепутаны айди
        // на фронте 2 - аутлайн, 1 - wg
        // на бэке 1 - айтлайн, 2 - wg
        var p = protocol == 1 ? 2 : 1



        setIsTariffComputing(true)
        fetch(getUrl("/api/tariff", props.tg) + "startCity=" + startCity + "&device=" + device + "&protocol=" + p + "&resultCity=" + resultCity)
            //fetch("http://localhost:5010" + "/api/available")
            .then(res => res.json())
            .then(
                (result) => {


                    setCurrentTariff(result[0])
                    setTariffs(result);
                    setIsTariffComputing(false)
                },
                (error) => {
                    //setIsLoaded(true);
                    //setError(error);
                }
            )

    }
    const { t, i18n } = useTranslation()

    React.useEffect(() => {
        i18n.changeLanguage(props.tg.initDataUnsafe.user != undefined ? props.tg.initDataUnsafe.user.language_code : g_language);

        //  getAvailableUsers();
        getFromCountrues();
        /* getTariff(startCity,; */
        getDevices(startCity)

        getTariff(startCity, device, protocol, country)
        fetch(getUrl("/api/available", props.tg) + "type=" + protocol)
            //fetch("http://localhost:5010" + "/api/available")
            .then(res => res.json())
            .then(
                (result) => {

                    setCountries(result[0].Flag)
                    setAvailable(result);
                },
                (error) => {
                    //setIsLoaded(true);
                    //setError(error);
                }
            )


        fetch(getUrl("/api/parametrs", props.tg) + "obj=" + 'chips')
            //fetch("http://localhost:5010" + "/api/parametrs?obj=" + 'chips')
            .then(res => res.json())
            .then(
                (result) => {

                    setChips(result)
                },
                (error) => {
                    //setIsLoaded(true);
                    //setError(error);
                }
            )
    }, [])

    const onMainBlockClick = () => {
        navigate("/tariff")
    }



    var getFlag = (value: any) => {

        if (value != "NO") {
            const Flag = Flags[value];
            return <Flag title={value} style={{ height: 15 }} />
        }
    }
    const [country, setCountries] = React.useState("ES");
    const [fromCountries, setFromCountries] = React.useState([])
    const [available, setAvailable] = React.useState([]);
    const [name, setName] = React.useState("");

    var onCountryClick = (id: any) => {


        setCountries(id)
        getTariff(startCity, device, protocol, id)
    }


    /*  const devices = [ */
    /*    {
           id: 1,
           a: <Box display={"flex"} onClick={() => { onDeviceClick(1) }} style={{ borderRadius: '12px', boxShadow: 'none' }} >
               <AndroidIcon />
               <div style={{ paddingLeft: 5 }}>Android</div>
           </Box>
       },
       {
           id: 2,
           a: <Box display={"flex"} onClick={() => { onDeviceClick(2) }} style={{ borderRadius: '12px', boxShadow: 'none' }} >
               <AppleIcon />
               <div style={{ paddingLeft: 5 }}>IPhone</div>
           </Box>
 
       },
       {
           id: 3,
           a: <Box display={"flex"} onClick={() => { onDeviceClick(3) }} className={startCity == 2 ? "device_disable" : ""} style={{ borderRadius: '12px', boxShadow: 'none' }} >
               <LaptopWindowsIcon />
               <div style={{ paddingLeft: 5 }}>MacOS</div>
           </Box>
       }, {
           id: 4,
           a: <Box display={"flex"} onClick={() => { onDeviceClick(4) }} className={startCity == 2 ? "device_disable" : ""} style={{ borderRadius: '12px', boxShadow: 'none' }} >
               <LaptopWindowsIcon />
               <div style={{ paddingLeft: 5 }}>Windows</div>
           </Box>
       },
       {
           id: 5,
           a: <Box display={"flex"} onClick={() => { }} className={"device_disable"} style={{ borderRadius: '12px', boxShadow: 'none' }} >
               <LaptopWindowsIcon />
               <div style={{ paddingLeft: 5 }}>IPad</div>
           </Box>
       },
       */
    /*     ] */

    var onCreateCodeClick = () => {


        if (props.isFree) {

            fetch(getUrl("/api/create_by_country", props.tg) + "id=" +
                ///* "http://localhost:5010" */  '/api/create_by_country?id='
                + (props.tg.initDataUnsafe.user != undefined ? props.tg.initDataUnsafe.user.id : current_user)
                + '&name='
                + (props.tg.initDataUnsafe.user != undefined ? props.tg.initDataUnsafe.user.id : "test")
                + "&flag=" + country
                + "&device=" + device
                + "&conn_name=" + name
                + "&protocol=" + protocol

                + "&from_country=" + startCity
                + "&tarif=" + currentTariff.Id

            )
                .then(res =>
                    navigate("/")
                );
        }
        else {
            if (props.isNew == null) {
                navigate(`/check`, {
                    state: {
                        id: country,
                        device_id: device,
                        name: name,
                        protocol: protocol,
                        from_country: startCity,
                        tarif: currentTariff,
                        conn: location.state.conn,
                        info: location.state.info,
                        device: device,
                        tariffPrice: currentTariff.Price

                    }
                });
            }
            else {
                navigate("/pay", {
                    state: {
                        id: country,
                        device_id: device,
                        name: name,
                        protocol: protocol,
                        from_country: startCity,
                        tarif: currentTariff,
                        conn: location.state.conn,
                        info: location.state.info,
                        device: device
                    }
                });
            }
        }
        /*       fetch("http://localhost:5010" + '/api/create_by_country?id='
                  + (props.tg.initDataUnsafe.user != undefined ? props.tg.initDataUnsafe.user.id :    current_user       )
                  + '&name='
                  + (props.tg.initDataUnsafe.user != undefined ? props.tg.initDataUnsafe.user.id : "test")
                  + "&flag=" + country
              )
                  .then(res =>
                      navigate("/")
                  ); */
    }

    var get_flag = (code: any) => {

        return <US style={{ height: 15 }} />


    }


    var handleTextChange = (e) => {

        debugger
        setName(e.target.value)
    }

    /*    function foo() {
           var boxes = document.getElementsByClassName('box');
           var tmp = 0;
           for (var i = 0; i < boxes.length; i++) {
             if ((boxes[i] as any).offsetHeight > tmp) {
               tmp =(boxes[i] as any).offsetHeight;
             }
           }
           for (var z = 0; z < boxes.length; z++) {
             (boxes[z] as any).style.height = tmp + "px";
           }
         } */


    const protocols = [

        {
            id: 2,
            base_id: 2,
            a:



                <Box display={"flex"} onClick={() => { onProcolClick(2) }} style={{ borderRadius: '12px', boxShadow: 'none' }} >

                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <Avatar alt="Remy Sharp" src={require("../../assets/images/outline.png")} />
                            <div style={{ marginLeft: 10 }}>
                                Outline (ShadowSocks)
                            </div>
                        </div>

                        {/*     <DynamicFeedIcon style={{ height: 15 }} /> */}
                        <div style={{}}>

                            <ul>
                                <li>{t("outline.text1")}</li>
                                <li>{t("outline.text2")}</li>
                                {/*  <li>Подключение чере сканирование QR-кода (потребуется второе устройство)</li> */}
                            </ul>
                        </div>
                    </div>

                    {/*  <NetworkCheckIcon style={{ height: 15 }} />
            <div style={{ paddingLeft: 5 }}>WireGuard</div> */}
                </Box>


        },
        {
            id: 1,
            base_id: 1,
            a: <Box display={"flex"} onClick={() => { onProcolClick(1) }} style={{ borderRadius: '12px', boxShadow: 'none' }} >
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Avatar alt="Remy Sharp" src={require("../../assets/images/wg.png")} />
                        <div style={{ marginLeft: 10 }}>
                            WireGuard
                        </div>
                    </div>

                    {/*     <DynamicFeedIcon style={{ height: 15 }} /> */}
                    <div style={{}}>

                        <ul>
                            <li>{t("wg.text1")}</li>
                            <li>{t("wg.text2")}</li>
                            {/*  <li>Подключение чере сканирование QR-кода (потребуется второе устройство)</li> */}
                        </ul>
                    </div>
                </div>

            </Box>
        },
    ]

    const customTheme = (outerTheme: Theme) =>
        createTheme({
            /*     palette: {
                    mode: outerTheme.palette.mode,
                }, */
            components: {
                MuiTextField: {

                    styleOverrides: {
                        /*   root: {
                              '--TextField-brandBorderColor': '#E0E3E7',
                              '--TextField-brandBorderHoverColor': '#B2BAC2',
                              '--TextField-brandBorderFocusedColor': '#6F7E8C',
                              '& label.Mui-focused': {
                                  color: props.tg.themeParams.text_color ?? '#1E88E5',
                              },
                          }, */
                    },
                },
                MuiOutlinedInput: {
                    styleOverrides: {
                        notchedOutline: {
/*                             borderColor: props.tg != undefined && props.tg.colorScheme == 'dark' ? props.tg.themeParams.text_color : '#bdbdbd',
 */                            border: "1px solid",
                            color: props.tg.themeParams.text_color ?? '#1E88E5',
                            borderColor: props.tg != undefined && props.tg.colorScheme == 'dark' ? props.tg.themeParams.text_color : '#bdbdbd'

                        },
                        root: {
                            [`&:hover .${outlinedInputClasses.notchedOutline}`]: {
                                border: "1px solid",
                                color: props.tg.themeParams.text_color ?? '#1E88E5',
                                borderColor: props.tg != undefined && props.tg.colorScheme == 'dark' ? props.tg.themeParams.text_color : '#bdbdbd'
                            },
                            [`&.Mui-focused .${outlinedInputClasses.notchedOutline}`]: {
                                border: "1px solid",
                                color: props.tg.themeParams.text_color ?? '#1E88E5',
                                /*     borderColor: '#bdbdbd' */
                                borderColor: props.tg != undefined && props.tg.colorScheme == 'dark' ? props.tg.themeParams.text_color : '#bdbdbd'
                            },
                        },
                    },
                },

            },
        });

    const onChipClick = (e: any) => {

        setName(e)
    }

    var keyPress = (e) => {

        if (e.key == 'Enter') {
            e.target.blur();
        }
    }



    const steps = [


       /*  {
            label: t("new_page.where_are_you_from"),
            color: { text_color },
            description: () => {
                return (
                    <Grid container spacing={{ xs: 1, md: 3 }} columns={{ xs: 9, sm: 8, md: 12 }}>
                        {
                            fromCountries?.map((step: any, index) => {
                                return (

                                    <Grid item xs={3} sm={4} md={4} key={index} style={{ display: 'flex', alignItems: 'stretch', width: '100%' }}>
                                        <Box sx={{
                                            p: 1,
                                            background:
                                                step.Id == startCity
                                                    ? button_color
                                                    : bg_color,
                                            borderRadius: '12px',
                                            mb: 1,
                                            border: '1px solid ' + button_color,
                                            display: 'flex',
                                            alignItems: 'stretch',
                                            width: '100%',
                                            color:
                                                step.Id == startCity
                                                    ? button_text_color
                                                    : text_color
                                        }}>
                                            <Box display={"flex"} onClick={() => { onStartClick(step.Id) }} style={{ borderRadius: '12px', justifyContent: 'center', boxShadow: 'none', alignItems: 'center', width: '100%', display: 'flex', flexDirection: 'column' }} >

                                                <Box>
                                                    {getFlag(step.Flag)}
                                                </Box>
                                                <Typography style={{ fontSize: "0.575em" }} variant="caption">{t("countries." + step.Flag)}</Typography>
                                            </Box>
                                        </Box>
                                    </Grid>
                                )
                            })
                        }
                    </Grid>
                )
            },
        }, */

        {
            label: t("new_page.device_selection"),
            description: () => {
                return <Stack>
                    {devices.map((step, index) => (
                        (step as any).Id == device
                            ?
                            <Box sx={{ p: 1, background: button_color, color: button_text_color, borderRadius: '12px', mb: 1, border: '1px solid ' + button_color }}>
                                {/* { (step as any).Name} */}
                                <Box display={"flex"} onClick={() => { onDeviceClick((step as any).Id) }} style={{ borderRadius: '12px', boxShadow: 'none' }} >
                                    {getDeviceIcon((step as any).Id)}
                                    {/* <AndroidIcon /> */}
                                    <div style={{ paddingLeft: 5 }}>{(step as any).Name}</div>
                                </Box>
                            </Box>
                            :
                            <Box sx={{ p: 1, background: bg_color, borderRadius: '12px', mb: 1, border: '1px solid' + button_color }}>
                                <Box display={"flex"} onClick={() => { onDeviceClick((step as any).Id) }} style={{ borderRadius: '12px', boxShadow: 'none' }} >
                                    {getDeviceIcon((step as any).Id)}
                                    {/* <AndroidIcon /> */}
                                    <div style={{ paddingLeft: 5 }}>{(step as any).Name}</div>
                                </Box>
                            </Box>
                    ))}
                </Stack>

            },
        },

        {
            label: t("new_page.protocol_selection"),
            description: () => {
                return <Stack>
                    {startCity == 2 || startCity == 100 || startCity == 4 || device == 3 || device == 4
                        ? protocols.filter(x => { return x.id == 2 }).map((step: any, index) => {

                            return (


                                step.id == protocol
                                    ?
                                    <Box sx={{ color: button_text_color, p: 1, background: button_color, borderRadius: '12px', mb: 1, border: '1px solid ' + button_color }}>
                                        {step.a}
                                    </Box>
                                    :
                                    <Box sx={{ p: 1, background: bg_color, borderRadius: '12px', mb: 1, border: '1px solid ' + button_color }}>
                                        {step.a}
                                    </Box>


                                /*       <Box sx={{ p: 1, background: props.tg != undefined && props.tg.colorScheme == 'dark' ? 'rgb(255 255 255 / 8%)' : 'rgb(248, 250, 252)', borderRadius: '12px', mb: 1, border: '1px solid rgb(238, 242, 246)' }}>
                                      {step.a}
                                  </Box> */
                            )
                        }
                        )
                        :
                        protocols.map((step: any, index) => {

                            return (


                                step.id == protocol
                                    ?
                                    <Box sx={{ p: 1, background: button_color, borderRadius: '12px', mb: 1, border: '1px solid ' + button_color, color: button_text_color }}>
                                        {step.a}
                                    </Box>
                                    :
                                    <Box sx={{ p: 1, background: bg_color, borderRadius: '12px', mb: 1, border: '1px solid ' + button_color }}>
                                        {step.a}
                                    </Box>


                                /*       <Box sx={{ p: 1, background: props.tg != undefined && props.tg.colorScheme == 'dark' ? 'rgb(255 255 255 / 8%)' : 'rgb(248, 250, 252)', borderRadius: '12px', mb: 1, border: '1px solid rgb(238, 242, 246)' }}>
                                      {step.a}
                                  </Box> */
                            )
                        }
                        )
                    }
                </Stack >

            },
        },
        {
            label: t("new_page.connection_name"),
            description: () => {

                return <div style={{ paddingTop: '10px' }}>
                    <Stack>

                        {/* <ValidationTextField value={name}  defaultValue={name} onChange={handleTextChange}></ValidationTextField> */}
                        {/*                         <TextField placeholder='Подключение'       InputProps={{sx: { borderRadius: '12px', borderColor: 'red' } }} value={name} defaultValue={name} style={{ borderRadius: '12px', color: props.tg.themeParams.text_color ?? '#1E88E5' }} 
                        
                        id="test" variant="outlined" onChange={handleTextChange}  focused /> */}
                        <ThemeProvider theme={customTheme(themeTelegram)}>
                            <TextField placeholder={t("connection") + ' #XXXXX'}
                                sx={{ input: { color: text_color } }}
                                InputProps={{ sx: { borderRadius: '12px' } }} value={name} defaultValue={name}
                                style={{ borderRadius: '12px', color: text_color }}
                                onKeyDown={keyPress}
                                id="test" variant="outlined" onChange={handleTextChange} focused />
                        </ThemeProvider>
                        <Box style={{
                            paddingTop: 10, display: 'flex',
                            justifyContent: "flex-start",
                            flexWrap: "wrap",
                            gridGap: 5,
                            alignContent: "flex-start"
                        }}>
                            {

                                chips.map((x: any) => {
                                    return (
                                        <Chip style={{ marginRight: 10 }} label={x.Value} /*     */ onClick={() => onChipClick(x.Value)} />
                                    )
                                })
                            }
                        </Box>


                    </Stack>
                </div>
            },
        },


        {
            label: t("new_page.tariff_selection"),
            code: "tarif",
            description: () => {
                return <Stack>
                    {tariffs.map((step, index) => {
                        return (
                            (step as any).Id == currentTariff?.Id ?
                                <Box onClick={() => { onTariffClick((step as any).Id) }} sx={{ p: 1, background: button_color, borderRadius: '12px', mb: 1, border: '1px solid ' + button_color, color: button_text_color }}>
                                    {(step as any).Limit} Гб за {(step as any)?.Price} WP per month
                                </Box>
                                :
                                <Box onClick={() => { onTariffClick((step as any).Id) }} sx={{ p: 1, borderRadius: '12px', mb: 1, border: '1px solid ' + button_text_color }}>
                                    {(step as any).Limit} Гб за {(step as any)?.Price} WP per month
                                </Box>
                        )
                    }
                    )}
                </Stack>

            },
        },

        {
            label: t("new_page.region_selection"),
            description: () => {
                return (<Grid container spacing={{ xs: 1, md: 3 }} columns={{ xs: 9, sm: 8, md: 12 }}>
                    {
                        available?.map((step: any, index) => {
                            return (

                                <Grid item xs={3} sm={4} md={4} key={index} style={{ display: 'flex', alignItems: 'stretch', width: '100%' }}>
                                    <Box sx={{
                                        p: 1,
                                        background:
                                            step.Flag == country
                                                ? button_color
                                                : bg_color,
                                        borderRadius: '12px',
                                        mb: 1,
                                        border: '1px solid ' + button_color,
                                        display: 'flex',
                                        alignItems: 'stretch',
                                        width: '100%',
                                        color:
                                            step.Flag == country
                                                ? button_text_color
                                                : text_color
                                    }}>
                                        <Box display={"flex"} onClick={() => { onCountryClick(step.Flag) }} style={{ borderRadius: '12px', justifyContent: 'center', boxShadow: 'none', alignItems: 'center', width: '100%', display: 'flex', flexDirection: 'column' }} >

                                            {/* <div style={{ display: 'flex', flexDirection: 'column',alignItems: 'stretch', width:'100%' }}> */}
                                            {/*  <div style={{ display: 'flex', alignItems: 'stretch', flexDirection: 'column', justifyContent: 'center', width:'100%' }}> */}
                                            <Box>
                                                {getFlag(step.Flag)}
                                            </Box>
                                            <Box style={{ display: 'flex', alignItems: 'center' }}>
                                                <Typography variant="caption">{t("countries." + step.Flag)}</Typography>
                                                {/* <Typography variant="caption">{step.Country} </Typography> */}
                                                <Box>
                                                    {
                                                        step.Flag == 'AE' || step.Flag == 'IN'
                                                            ? <StarIcon fontSize='small' className={step.Flag == country ? 'star_country__icon_clicked' : 'star_country__icon'} />
                                                            : <></>
                                                    }
                                                </Box>
                                            </Box>

                                            {/*   <div style={{ }}>
                                                        {step.Country}
                                                    </div> */}
                                            {/*    </div> */}



                                            {/* </div> */}


                                        </Box>
                                    </Box>
                                </Grid>
                            )
                        })
                    }
                </Grid>)


                    /* <Stack>
                    {
                        available.map((step: any, index) => {

                            return (
                                step.Flag == country ?
                                    <Box className={step.ServerId == null ? "device_disable" : ""} sx={{ p: 1, background: 'rgb(255, 193, 7)', borderRadius: '12px', mb: 1, border: '1px solid rgb(255, 193, 7)' }}>

                                        <Box className={step.ServerId == null ? "device_disable" : ""} display={"flex"} onClick={() => { onCountryClick(step.Flag) }} style={{ alignItems: 'center' }} >
                                            {getFlag(step.Flag)}
                                            <div style={{ paddingLeft: 5 }}>{step.Country}</div>
                                            {
                                                step.Flag == 'AE'
                                                    ? <StarIcon fontSize='small' className='star_country__icon_clicked' />
                                                    : <></>
                                            }
                                        </Box>
                                    </Box>
                                    :
                                    <Box className={step.ServerId == null ? "device_disable" : ""} sx={{ p: 1, background: props.tg != undefined && props.tg.colorScheme == 'dark' ? 'rgb(255 255 255 / 8%)' : 'rgb(248, 250, 252)', borderRadius: '12px', mb: 1, color: props.tg.themeParams.text_color ?? '#1E88E5', border: '1px solid rgb(238, 242, 246)' }}>
                                        <Box display={"flex"} onClick={() => { onCountryClick(step.Flag) }} style={{ alignItems: 'center' }} >
                                            {getFlag(step.Flag)}
                                            <div style={{ paddingLeft: 5 }}>{step.Country}</div>
                                            {
                                                step.Flag == 'AE'
                                                    ? <StarIcon fontSize='small' className='star_country__icon' />
                                                    : <></>
                                            }
                                        </Box>
                                    </Box>
                            )
                        }
                        )
                    }
                </Stack > */}

        },


    ];


    function getTestStepper() {

        var res: any[] = [];

        var i = props.users == false ? 1 : 0
        for (i; i < steps.length; i++) {

            if (props.users == false && steps[i].code == "tarif") {
                continue
            }


            if (steps[i].code == "tarif" && (startCity == 1 || startCity == 3 || (startCity >= 4 && startCity <= 100)) && (device == 1 || device == 2)) {
                continue
            }



            res.push(steps[i]);
        }
        return res
    }


    var getDeviceName = (id: any) => {
        switch (id) {
            case 1:
                return "Android"
            case 2:
                return "IPhone"
            case 4:
                return "Windows"
            case 3:
                return "MacOS"
            default:
                return "Other"
        }
    }

    var getDeviceIcon = (id: any) => {
        switch (id) {
            case 1:
                return <AndroidIcon />
            case 2:
                return <AppleIcon />
            case 3:
                return <LaptopWindowsIcon />
            case 4:
                return <LaptopWindowsIcon />
            case 999:
                return <LanguageIcon />
            case 998:
                return <RouterIcon />
            case 997:
                return <TvIcon />
            default:
                return <LaptopWindowsIcon />
        }
    }

    return (
        <Box sx={{ width: '100%', pb: 2, mt: 2 }} >
            <Box sx={{ pb: 2 }} className="sticky" style={{ background: secondary_bg_color }} >
                <Box style={{ background: bg_color, borderRadius: '12px', boxShadow: 'none' }} sx={{ p: 2 }}>

                    <Box style={{ color: props.tg.themeParams.text_color ?? '#1E88E5', display: 'flex' }}>
                        <div style={{ marginRight: 10, alignItems: 'center' }}>
                            {t("tariff")}:
                        </div>
                        {
                            isTariffComputing
                                ? <Skeleton width={'50%'}></Skeleton>
                                :
                                <div>
                                    {
                                        startCity == 2 ||
                                            ((startCity == 1 ||
                                                (startCity >= 4 && startCity < 100))
                                                && (device == 3 || device == 4))
                                            ? currentTariff?.Price + " WP/" + t("month")
                                            :
                                            (currentTariff?.Price == undefined
                                                ? 3
                                                : currentTariff?.Price)

                                            + " WP/" + t("day")}
                                </div>
                        }
                        {/*  Тариф: {startCity == 2 || (startCity == 1 && (device == 3 || device == 4)) ? currentTariff?.Price + " Руб/месяц" : 3 + " Руб/день"} */}
                    </Box>

                    <Box style={{ color: props.tg.themeParams.text_color ?? '#1E88E5', display: 'flex' }}>
                        <div style={{ marginRight: 10, alignItems: 'center' }}>
                            {t("device")}:
                        </div>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            {getDeviceIcon(device)} {getDeviceName(device)}
                        </div>

                    </Box>

                    <div

                    />

                    {
                        country == "IN"
                            ? <Box style={{ color: props.tg.themeParams.text_color ?? '#1E88E5', display: 'flex' }}>
                                <div style={{ marginRight: 10, alignItems: 'center' }}>
                                    Лимит:
                                </div>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    100 Гб
                                </div>

                            </Box>
                            : <></>

                    }
                </Box>
            </Box>
            <Box style={{ background: bg_color, borderRadius: '12px', boxShadow: 'none' }} sx={{ p: 2 }}>
                <Stepper activeStep={activeStep} connector={<ColorlibConnector />} orientation="vertical" style={{ color: text_color }} sx={{ mb: 1 }}>
                    {getTestStepper().map((step, index) => {

                        /*   if (index == 0 && props.users == false) {
                              return <></>
                          } */

                        return (
                            <Step key={step.label} sx={{ mt: 1 }}/* style={{color:props.tg.themeParams.text_color ?? 'red'}} */ >
                                <StepLabel


                                    StepIconComponent={ColorlibStepIcon}

                                >
                                    <div style={{ color: text_color }}>
                                        {step.label}
                                    </div>
                                </StepLabel>
                                <StepContent>
                                    {step.description()}
                                    <Box sx={{ mb: 2 }} /* style={{background: props.tg != undefined && props.tg.colorScheme == 'dark' ? 'rgb(255 255 255 / 8%)' : 'rgb(248, 250, 252)'}} */>
                                        <div>
                                            {
                                                index === getTestStepper().length - 1
                                                    ? /* <Button
                                                variant="contained"
                                                onClick={onCreateCodeClick}
                                                sx={{ mt: 1, mr: 1 }}
                                            >
                                                Получить код
                                            </Button>
 */
                                                    protocol == 2
                                                        ?

                                                        <  Button
                                                            disabled={isTariffComputing}
                                                            onClick={onCreateCodeClick} variant="contained" aria-label="add to shopping cart" /* disabled={setEnabled()} */ sx={{ mt: 1, mr: 1, borderRadius: 0, }}
                                                            style={{ borderRadius: '10px', textTransform: 'none', boxShadow: 'none' }}/*  onClick={onPayClick} */>
                                                            {t("buttons.getKey")}
                                                            <LinkIcon sx={{ pl: 1 }} />
                                                        </Button >
                                                        :


                                                        < Button onClick={onCreateCodeClick} variant="contained" aria-label="add to shopping cart" /* disabled={setEnabled()} */ sx={{ mt: 1, mr: 1, borderRadius: 0 }}
                                                            style={{ borderRadius: '10px', textTransform: 'none', boxShadow: 'none' }}/*  onClick={onPayClick} */>
                                                            {t("buttons.getCode")}
                                                            <QrCode2Icon sx={{ pl: 1 }} />
                                                        </Button >
                                                    : /* <Button
                                    variant="contained"
                                    onClick={handleNext}
                                    sx={{ mt: 1, mr: 1 }}
                                >
                                    Далее
                                </Button> */
                                                    <Button onClick={handleNext} variant="contained" aria-label="add to shopping cart" /* disabled={setEnabled()} */ sx={{ mt: 1, mr: 1, borderRadius: 0, }}
                                                        style={{ borderRadius: '10px', textTransform: 'none', boxShadow: 'none' }}/*  onClick={onPayClick} */>
                                                        {t("buttons.next")}
                                                        <KeyboardArrowRightIcon sx={{}} />
                                                    </Button >


                                            }

                                            {
                                                index === 0
                                                    ? <></>
                                                    : <Button
                                                        disabled={index === 0}
                                                        onClick={handleBack}

                                                        sx={{ mt: 1, mr: 1, borderRadius: 0, }}
                                                        style={{ borderRadius: '10px', textTransform: 'none', boxShadow: 'none', background: "transparent", color: text_color }}
                                                    >
                                                        {t("buttons.previous")}
                                                    </Button>
                                            }


                                        </div>
                                    </Box>
                                </StepContent>
                            </Step>
                        )
                    }
                    )
                    }
                </Stepper >
            </Box>
            {/*  {
                activeStep === 1 && (
                    <Paper square elevation={0} sx={{ p: 3 }} style={{ background: props.tg != undefined && props.tg.colorScheme == 'dark' ? 'rgb(255 255 255 / 8%)' : 'rgb(248, 250, 252)' }}>
                        <Typography>All steps completed - you&apos;re finished</Typography>
                        <Button onClick={handleReset} sx={{ mt: 1, mr: 1 }}>
                            Reset
                        </Button>
                    </Paper>
                )
            } */}
        </Box >
    );
}